import { createRouter,createWebHashHistory,createWebHistory} from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'login',
    meta: {title:'专家后台'},
    component: () => import('../views/user/login.vue'),
  },
  {
    name: 'admin',
    path: '/admin',
    meta: {title:'专家管理后台'},
    component: () => import('../views/admin/login.vue'),
   },
   {
     path: '/loginWeb',
     name: 'loginWeb',
     meta: {title:'专家后台'},
     component: () => import('../views/user/loginWeb.vue'),
   },
  {
    name: 'profRegister',
    path: '/user/profRegister',
    meta: {title:'专家注册'},
    component: () => import('../views/user/profRegister.vue')
  },
  {
    name: 'profRegisterAdmin',
    path: '/user/profRegisterAdmin',
    meta: {title:'专家注册'},
    component: () => import('../views/user/profRegisterAdmin.vue')
  },
  {
    name: 'forgetPassword',
    path: '/user/forgetPassword',
    meta: {title:'忘记密码'},
    component: () => import('../views/user/forgetPassword.vue')
  },
  {
    name: 'supplierLogin',
    path: '/supplier/login',
    meta: { title: '供应商登录' },
    component: () => import('../views/supplier/login.vue')
  },
  {
    name: 'supplierRegister',
    path: '/supplier/register',
    meta: { title: '供应商注册' },
    component: () => import('../views/supplier/register.vue')
  },
  {
    name: 'supplierforgetPassword',
    path: '/supplier/forgetPassword',
    meta: { title: '供应商忘记密码' },
    component: () => import('../views/supplier/forgetPassword.vue')
  },
  {
    name: 'app',
    path:'/app',
    meta: {title:'专家后台'},
    component: () => import('../views/user/App.vue'),
    children: [
        {
          path: '/user/profBaseInfo',
          name: 'profBaseInfo',
          component: () => import('../views/user/prfoBaseInfo.vue')
        },
        {
          path: '/user/professionInfo',
          name: 'professionInfo',
          component: () => import('../views/user/professionInfo.vue')
        },
        {
          path: '/user/workInfo',
          name: 'workInfo',
          component: () => import('../views/user/workInfo.vue')
        },
        {
          path: '/user/qualificationInfo',
          name: 'qualificationInfo',
          component: () => import('../views/user/qualificationInfo.vue')
        },
        {
          path: '/user/proRepassword',
          name: 'proRepassword',
          component: () => import('../views/user/proRepassword.vue')
        },
        {
          path: '/user/searchInfo',
          name: 'searchInfo',
          component: () => import('../views/user/searchInfo.vue')
        },
        {
          path: '/user/searchInfoUpdate',
          name: 'searchInfoUpdate',
          component: () => import('../views/user/searchInfoUpdate.vue')
        },
        {
          path: '/user/tipInfo',
          name: 'tipInfo',
          component: () => import('../views/user/tipInfo.vue')
        },
        {
          path: '/user/chooseRecord',
          name: 'chooseRecord',
          component: () => import('../views/user/chooseRecord.vue')
        },
        {
          path: '/user/askLeave',
          name: 'askLeave',
          component: () => import('../views/user/askLeave.vue')
        },
        {
          path: '/user/ignoreCompany',
          name: 'ignoreCompany',
          component: () => import('../views/user/ignoreCompany.vue')
        },
     ]
  },
  {
       name: 'adminApp',
       path: '/adminApp',
       meta: {title:'专家管理后台'},
       component: () => import('../views/admin/App.vue'),
       children:
         [{
           path: '/admin/home',
           name: 'adminHome',
           component: () => import('../views/admin/admin.vue')
         }, {
           path: '/admin/checkProfInfo',
           name: 'checkProfInfo',
           component: () => import('../views/admin/expertInfo/checkProfInfo.vue')
         }, {
           path: '/admin/category',
           name: 'category',
           component: () => import('../views/admin/expertInfo/category.vue')
         }, {
           path: '/admin/professionalTitle',
           name: 'professionalTitle',
           component: () => import('../views/admin/expertInfo/professionalTitle.vue')
         },
         {
           path: '/admin/profcenter',
           name: 'profcenter',
           component: () => import('../views/admin/expertInfo/profcenter.vue')
         }, {
           path: '/admin/profcenterCheck',
           name: 'profcenter_check',
           component: () => import('../views/admin/expertInfo/profcenterCheck.vue')
         }, {
           path: '/admin/readProfInfo',
           name: 'readProfInfo',
           component: () => import('../views/admin/expertInfo/readProfInfo.vue')
         },{
           path: '/admin/readProfInfoBasic',
           name: 'readProfInfoBasic',
           component: () => import('../views/admin/expertInfo/readProfInfoBasic.vue')
         },{
           path: '/admin/profcenterRecheck',
           name: 'profcenterRecheck',
           component: () => import('../views/admin/expertInfo/profcenterRecheck.vue')

         },{
           path: '/admin/recheckProfInfo',
           name: 'recheckProfInfo',
           component: () => import('../views/admin/expertInfo/recheckProfInfo.vue')

         },{
           path: '/admin/projectList',
           name: 'projectList',
           component: () => import('../views/admin/expertChoose/projectList.vue')

         },{
           path: '/admin/projectInfo',
           name: 'projectInfo',
           component: () => import('../views/admin/expertChoose/projectInfo.vue')

         },{
           path: '/admin/projectChooseList',
           name: 'projectChooseList',
           component: () => import('../views/admin/expertChoose/projectChooseList.vue')

         },{
           path: '/admin/projectChooseRecord',
           name: 'projectChooseRecord',
           component: () => import('../views/admin/expertChoose/projectChooseRecord.vue')

         },{
           path: '/admin/projectReviewBase',
           name: 'projectReviewBase',
           component: () => import('../views/admin/expertChoose/projectReviewBase.vue')

         },{
           path: '/admin/projectRandomChoose',
           name: 'projectRandomChoose',
           component: () => import('../views/admin/expertChoose/projectRandomChoose.vue')

         },{
           path: '/admin/projectChooseBlack',
           name: 'projectChooseBlack',
           component: () => import('../views/admin/expertChoose/projectChooseBlack.vue')

         },{
           path: '/admin/projectSelfConfirm',
           name: 'projectSelfConfirm',
           component: () => import('../views/admin/expertChoose/projectSelfConfirm.vue')

         },{
           path: '/admin/projectReviewRecord',
           name: 'projectReviewRecord',
           component: () => import('../views/admin/expertChoose/projectReviewRecord.vue')

         },{
           path: '/admin/projectReviewResult',
           name: 'projectReviewResult',
           component: () => import('../views/admin/expertChoose/projectReviewResult.vue')

         },{
           path: '/admin/askLeaveList',
           name: 'askLeaveList',
           component: () => import('../views/admin/expertChoose/askLeaveList.vue')

         },{
           path: '/admin/confirmExpert',
           name: 'confirmExpert',
           component: () => import('../views/admin/expertChoose/confirmExpert.vue')

         },{
           path: '/admin/chooseExpertMessage',
           name: 'chooseExpertMessage',
           component: () => import('../views/admin/expertChoose/chooseExpertMessage.vue')

         },{
           path: '/admin/supplierFormal/list',
           name: 'supplierFormalList',
           component: () => import('../views/admin/supplier/formal/list.vue')

         },{
           path: '/admin/supplierFormal/search',
           name: 'supplierFormalsearch',
           component: () => import('../views/admin/supplier/formal/search.vue')

         },{
           path: '/admin/supplierEnter/list',
           name: 'supplierEnterList',
           component: () => import('../views/admin/supplier/enter/list.vue')

         },{
           path: '/admin/supplierEnter/search',
           name: 'supplierEnterSearch',
           component: () => import('../views/admin/supplier/enter/search.vue')

         },{
           path: '/admin/supplierEnter/check',
           name: 'supplierEnterCheck',
           component: () => import('../views/admin/supplier/enter/check.vue')

         },{
           path: '/admin/supplierUpdate/list',
           name: 'supplierUpdateList',
           component: () => import('../views/admin/supplier/update/list.vue')

         },{
           path: '/admin/supplierUpdate/search',
           name: 'supplierUpdateSearch',
           component: () => import('../views/admin/supplier/update/search.vue')

         },{
           path: '/admin/supplierUpdate/check',
           name: 'supplierUpdateCheck',
           component: () => import('../views/admin/supplier/update/check.vue')

         },{
           path: '/admin/supplier/cate',
           name: 'supplierCate',
           component: () => import('../views/admin/supplier/cate.vue')

         },{
           path: '/admin/provinceExpert/list',
           name: 'provinceExpertList',
           component: () => import('../views/admin/provinceExpert/list.vue')
         }],
  },
  {
    path: '/admin/tenderInfo',
    name: 'chooseExpertTenderInfo',
    component: () => import('../views/admin/expertChoose/tenderInfo.vue')

  },
  {
      name: 'supplierIndex',
      path: '/supplier/index',
      meta: { title: '供应商服务平台' },
      component: () => import('../views/supplier/index.vue'),
      children:
        [{
          path: '/supplier/updateBase',
          name: 'supplierUpdateBase',
          component: () => import('../views/supplier/update/base.vue')
        },{
          path: '/supplier/updateCategory',
          name: 'supplierUpdateCategory',
          component: () => import('../views/supplier/update/category.vue')
        },{
          path: '/supplier/updateBank',
          name: 'supplierUpdateBank',
          component: () => import('../views/supplier/update/bank.vue')
        },{
          path: '/supplier/updateBusiness',
          name: 'supplierUpdateBusiness',
          component: () => import('../views/supplier/update/business.vue')
        },{
          path: '/supplier/updateJuridical',
          name: 'supplierUpdateJuridical',
          component: () => import('../views/supplier/update/juridical.vue')
        },{
          path: '/supplier/rePassword',
          name: 'rePassword',
          component: () => import('../views/supplier/rePassword.vue')
        },{
          path: '/supplier/readFormal',
          name: 'readFormal',
          component: () => import('../views/supplier/formal.vue')
        },{
          path: '/supplier/readUnFormal',
          name: 'readUnFormal',
          component: () => import('../views/supplier/unFormal.vue')
        }]
    }
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})
export default router
