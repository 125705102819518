import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import home from './views/Home.vue'
import router from './router'
import store from './store'
//import installElementPlus from './plugins/element'
import axios from 'axios'
import Vueaxios from 'vue-axios'
import '../src/assets/font/iconfont.css';

import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'



router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

//createApp(home).use(ElementPlus,{locale}).use(store).use(router).use(installElementPlus).use(Vueaxios,axios).mount('#app')
//createApp(adminApp).use(ElementPlus).use(store).use(adminrouter).use(installElementPlus).use(Vueaxios,axios).mount('#admin')
createApp(home).use(ElementPlus,{locale}).use(store).use(router).use(Vueaxios,axios).mount('#app')
